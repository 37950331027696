import React from 'react';
import Topbar from './Components/Home2/Topbar';
import Navbar from './Components/Home2/Navbar';
import { Route, Routes } from 'react-router-dom';
import Home from './Components/Home2/Home';
import Main from './Components/Home/main';


function App() {
  return (
    <div className='font-inter'>
      <Topbar />
      <Navbar />
      <Routes>
        <Route path='/' Component={Home} />
      </Routes>
    </div>
  );
}

export default App;

import React, { useState } from 'react'
import logo from '../../Images/logo.png'
import { FaBars } from 'react-icons/fa'
import { FaX } from 'react-icons/fa6';
import '../../App.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [ isOpen, setisOpen ] = useState(false);

    const handleClick = ()=> {
        setisOpen(!isOpen);
    }
  return (
    <section className='px-[5%] lg:px-16 flex justify-between items-center relative'>
        <Link to='#'><img src={logo} alt='logo' className='h-12 md:h-24 cursor-pointer'/></Link>
        <section className='flex items-center font-semibold'>
            <ul className='gap-5 lg:gap-10 hidden md:flex text-sm'>
                <li className='n relative w-fit'>Home</li>
                <li className='n relative w-fit'>About Us</li>
                <li className='n relative w-fit'>Services</li>
                <li className='n relative w-fit'>Blog</li>
                <li className='n relative w-fit'>Contact Us</li>
            </ul>
        </section>
        <div className='justify-center items-center hidden md:flex text-sm'>
            <button className='border border-primary2 px-5 py-3 rounded-lg hover:text-white hover:bg-primary2 hover:scale-110 transition-all shadow-2xl'>Know More</button>
        </div>
        {isOpen ?
            <FaX onClick={handleClick} className='cancel'/>:
            <FaBars className='block md:hidden text-xl bars' onClick={handleClick}/>
        }
        {isOpen ?
            <section className='absolute top-[63px] right-0 h-[100vh] w-[70%] sm:w-[40%] text-primary bg-white z-10 ls'>
                <ul className='flex flex-col gap-3 py-4'>
                    <li className='hover:text-white hover:bg-primary px-4'>Home</li>
                    <li className='hover:text-white hover:bg-primary px-4'>About Us</li>
                    <li className='hover:text-white hover:bg-primary px-4'>Services</li>
                    <li className='hover:text-white hover:bg-primary px-4'>Blog</li>
                    <li className='hover:text-white hover:bg-primary px-4'>Contact Us</li>
                </ul>
            </section> :null
        }
    </section>
  )
}

export default Navbar

import React from 'react'
import image1 from '../../Images/Group 25.png'
import image2 from '../../Images/Group 26.png'
import image3 from '../../Images/Group 27.png'
import image4 from '../../Images/infrastructure.png'
import image5 from '../../Images/renewable.png'
import image6 from '../../Images/business.png'
import image7 from '../../Images/mining.png'
import image8 from '../../Images/technology.png'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick'

const IndustryCoverage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    nextArrow: <></>,
    prevArrow: <></>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className='px-[5%] md:px-[10%] p-5 bg-primary2 text-white flex flex-col gap-5 justify-center items-center'>
      <h1 className='text-center text-lg md:text-3xl font-bold'>Industry Coverage</h1>
      <p className='text-center sm:w-[70%] md:text-base text-sm'>From Investment Banking to Business Financing and HR consulting, our expertise spans across diverse sectors, ensuring tailored solutions for every business need.</p>
      <section className=' w-full mb-10  sm:mb-5 h-full'>
        <Slider {...settings}>

        <section className='relative xs:w-[70%] p-5 md:w-[20%] flex flex-col justify-end items-center xs:h-auto w-full h-[400px] text-white'>
          <img src={image1} alt='screen' className='w-full h-full'/>
          <h1 className='absolute top-[85%] left-1/2 -translate-x-1/2 -translate-y-1/2 text-xl xs:text-lg md:text-lg lg:text-base'>Mining and Geology</h1>
        </section>
        <section className='relative xs:w-[70%] p-5 md:w-[20%] flex flex-col justify-end items-center xs:h-auto w-full h-[400px] text-white'>
          <img src={image2} alt='screen' className='w-full h-full'/>
          <h1 className='absolute top-[85%] left-1/2 -translate-x-1/2 -translate-y-1/2  text-xs sm:text-sm md:text-base lg:text-base'>Healthcare</h1>
        </section>
        <section className='relative xs:w-[70%] p-5 md:w-[20%] flex flex-col justify-end items-center xs:h-auto w-full h-[400px] text-white'>
          <img src={image3} alt='screen' className='w-full h-full'/>
          <h1 className='absolute top-[85%] left-1/2 -translate-x-1/2 -translate-y-1/2 text-xs sm:text-sm md:text-base lg:text-base'>Renewable Energy</h1>
        </section>
        <section className='relative xs:w-[70%] p-5 md:w-[20%] flex flex-col justify-end items-center xs:h-auto w-full h-[400px] text-white'>
          <img src={image4} alt='screen' className='w-full h-full'/>
          <h1 className='absolute top-[85%] left-1/2 -translate-x-1/2 -translate-y-1/2  text-xs sm:text-sm md:text-base lg:text-base'>Oil and Gas</h1>
        </section>
        <section className='relative xs:w-[70%] p-5 md:w-[20%] flex flex-col justify-end items-center xs:h-auto w-full h-[400px] text-white'>
          <img src={image5} alt='screen' className='w-full h-full'/>
          <h1 className='absolute top-[85%] left-1/2 -translate-x-1/3 -translate-y-1/2  text-xs sm:text-sm md:text-base lg:text-base'>Infrastructure Development</h1>
        </section>
        <section className='relative xs:w-[70%] p-5 md:w-[20%] flex flex-col justify-end items-center xs:h-auto w-full h-[400px] text-white'>
          <img src={image6} alt='screen' className='w-full h-full'/>
          <h1 className='absolute top-[85%] left-1/2 -translate-x-1/2 -translate-y-1/2 text-xs sm:text-sm md:text-base lg:text-base'>Technology</h1>
        </section>
        <section className='relative xs:w-[70%] p-5 md:w-[20%] flex flex-col justify-end items-center xs:h-auto w-full h-[400px] text-white'>
          <img src={image7} alt='screen' className='w-full h-full'/>
          <h1 className='absolute top-[85%] left-1/2 -translate-x-1/2 -translate-y-1/2  text-xs sm:text-sm md:text-base lg:text-base'>Real Estate Finance</h1>
        </section>
        <section className='relative xs:w-[70%] p-5 md:w-[20%] flex flex-col justify-end items-center xs:h-auto w-full h-[400px] text-white'>
          <img src={image8} alt='screen' className='w-full h-full'/>
          <h1 className='absolute top-[85%] left-1/2 -translate-x-1/2 -translate-y-1/2 text-xs sm:text-sm md:text-base lg:text-base'>Business Services</h1>
        </section>
        </Slider>
      </section>
    </div>
  )
}

export default IndustryCoverage

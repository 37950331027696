import React from 'react'
import logo from '../../Images/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import { FaEnvelope, FaPhoneAlt } from 'react-icons/fa'
import {  FaLocationDot } from 'react-icons/fa6';
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='px-[5%] md:px-[10%] relative w-full h-full text-white bg-black flex flex-col items-center gap-10 justify-center pb-10'>
        <section className='-mt-[7%] bg-primary2 z-10 rounded-lg p-5 md:p-10 text-center flex flex-col gap-5 items-center w-[90%] md:w-[80%] px-[5%] md:px-[10%]' >
            <p className='text-xss xs:text-xs sm:text-sm'>Headquartered in Bahrain, Black Eagle Global Venture extends its operational reach across Dubai, Saudi Arabia, and India through our associate companies. With a diverse portfolio spanning multiple industries, we offer unparalleled expertise to support your growth ambitions effectively.</p>
            <button className='text-xs sm:text-md md:text-lg lg:text-xl px-5 py-2 bg-primary w-fit rounded-md shadow-lg'>Get a Consultation</button>
        </section>
        <section className='flex flex-col md:flex-row bg-black w-full gap-10 md:gap-5 justify-between'>
            <section className='md:w-[33%] text-xs sm:text-base md:text-xs'>
                <form className='border border-white rounded-lg flex flex-col p-5 gap-5 w-full'>
                    <p className='uppercase text-primary'>feedback</p>
                    <p className='md:text-base text-slate-500'>Seeking personalized support?</p>
                    <p>Request a call from our team</p>
                    <input type='text' placeholder='Your Name' className='border border-primary rounded-md p-1 px-2 bg-transparent'/>
                    <input type='tel' placeholder='Phone Number' className='border border-primary rounded-md p-1 px-2 bg-transparent'/>
                    <button className='p-2 rounded-md bg-primary w-fit '>Send a Request</button>
                </form>
            </section>

            <section className='flex  flex-col gap-5 text-xs sm:text-base md:text-xs md:w-[33%]'>

                <section className='flex flex-col'>
                    <img src={logo} alt='logo' className='h-32 w-32'/>
                    <p>We became the best among others through a steadfast commitment to excellence.</p>
                </section>
                <section className='flex flex-col gap-2'>
                    <p className='text-primary'>Important Links</p>
                    <p>Home</p>
                    <p>About Us</p>
                    <p>Services</p>
                    <p>Blog</p>
                    <p>Contact Us</p>
                </section>
            </section>

            <section className='flex justify-between flex-col gap-5 text-xs sm:text-base md:text-xs md:w-[33%]'>
                <section className='flex flex-col gap-2'>
                    <p className='text-primary'>Services</p>
                    <p>Capital Advisory</p>
                    <p>Consultancy Services</p>
                </section>
                <section className='flex gap-5'>
                    <FontAwesomeIcon icon={faFacebook} />
                    <FontAwesomeIcon icon={faTwitter} />
                    <FontAwesomeIcon icon={faInstagram} />
                    <FontAwesomeIcon icon={faLinkedin} />
                    <FontAwesomeIcon icon={faYoutube} />
                </section>
                <section className='flex flex-col gap-5'>
                    <p className='text-primary'>Contact Us</p>
                    <div className='flex gap-2'>
                        <FaPhoneAlt className=''/>
                        <Link href='tel:+97338839020' className='lg:block hidden'>+973 38839020</Link>
                    </div>
                    <div className='flex gap-2'>
                        <FaEnvelope className=''/>  
                        <Link href='mailto:info@blackeagleglobal.com' className=''>info@blackeagleglobal.com</Link>
                    </div>
                    <div className='flex gap-2'>
                        <FaLocationDot className=''/>
                        <p className=''>Mahooz, Kingdomof Bahrain</p>
                    </div>
                </section>
            </section>
        </section>
    </div>
  )
}

export default Footer

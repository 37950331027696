import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Logo1 from '../../Images/be1.jpeg';
import Logo2 from '../../Images/be2.png';
import Logo3 from '../../Images/be3.png';
import Logo4 from '../../Images/be4.png';
import line from '../../Images/line.png'
import newf from '../../Images/new.png'
import dex from '../../Images/dex.png'

const AssociateCompanies = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="bg-gray-100 py-8 relative flex flex-col justify-center items-center gap-5">
      <div className='relative h-6 xs:h-8 md:h-12 w-full'>
        <h2 className="text-sm xs:text-lg md:text-3xl font-inter font-bold text-center">Our Associate Companies</h2>
        <img src={line} alt='line' className='bottom-0 absolute w-full h-full'/>
      </div>
      <div className='w-[80%] pt-10'>
      <Slider {...settings} className='bg-transparent'>
        <div className="flex w-full items-center justify-center rounded-lg px-6">
          <img src={newf} alt="no img" className="rounded-lg w-full object-contain" />
        </div>
        <div className="flex w-full items-center justify-center rounded-lg px-6">
          <img src={Logo2} alt="no img" className="rounded-lg w-full object-contain " />
        </div>
        <div className="flex w-full items-center justify-center rounded-lg px-6">
          <img src={Logo3} alt="no img" className="rounded-lg w-full object-contain" />
        </div>
        <div className="flex w-full items-center justify-center rounded-lg px-6">
          <img src={dex} alt="no img" className="rounded-lg w-full object-contain" />
        </div>
        {/* Add more divs with logos as needed */}
      </Slider>
      </div>
    </section>
  );
};

export default AssociateCompanies;
